import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import CategoriesContainer from './components/CategoriesContainer';


class App extends Component {
  render() {
    return (
      <div className="App">
        <header>
          <h1 className="App-title">Olá</h1>
        </header>
        <body>
        
          <CategoriesContainer />
        </body>

      </div>
    );
  }
}

export default App;
