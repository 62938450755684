import React, { Component } from 'react';
import axios from 'axios';

import CategoryForm from './CategoryForm';

class CategoriesContainer extends Component {
    constructor(props){
        super(props)
        this.state = {
            categories: []
        }

        this.addNewCategory = this.addNewCategory.bind(this)
    }

    componentDidMount() {
        axios.get('http://rails.javaworld.com.br/api/v1/categories')
        .then(response => {
            console.log(response)
            this.setState({
                categories: response.data
            })
        })
        .catch(error => console.log(error))
    }

    addNewCategory = (name, ctype) => {
        axios.post( 'http://rails.javaworld.com.br/api/v1/categories', { category: {name, ctype} })
        .then(response => {
            console.log(response)
            const categories = [ ...this.state.categories, response.data ]
            this.setState({categories})
        })
        .catch(error => {
            console.log(error)
        })
    }

    render() {
        return (

            <div className="categories-container">
            <CategoryForm  onCategory={this.addNewCategory}/>
                {this.state.categories.map( category => {
                    return (
                        <div className="single-category" key={category.id}>
                            <h4>{category.name}</h4>
                            <p>{category.ctype}</p>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default CategoriesContainer;
