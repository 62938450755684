import React from 'react';

const CategoryForm = ({onCategory = f => f}) => {
    let name, type
    const submit = e => {
        e.preventDefault()
        onCategory(name.value, type.value)
        name.value = ''
        type.value = ''
        name.focus()
    }

    return (
        <form onSubmit={submit}>
            <input  ref={input => name = input}
                    type="text"
                    placeholder="Nome..." required />
                    <br />
            <input  ref={input => type = input}
                    type="text"
                    placeholder="Tipo..." required />
                    <br />
            <button>Adicionar Categoria</button>
        </form>
    )
}

export default CategoryForm;